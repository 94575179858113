import { ReactElement } from 'react';
import { ActivityIndicator, StyleProp, ViewStyle } from 'react-native';
import { withTheme } from 'styled-components/native';

import theme, { Color, ThemeProps } from '@legacy-components/theme';

export enum SpinnerSizes {
  LARGE = 'large',
  SMALL = 'small',
}

interface Props extends ThemeProps {
  color?: Color;
  size?: SpinnerSizes;
  style?: StyleProp<ViewStyle>;
}

const LoadingSpinner = ({
  color = Color.PRIMARY,
  size = SpinnerSizes.LARGE,
  style,
}: Props): ReactElement => (
  <ActivityIndicator color={theme.color[color]} size={size} style={style} />
);

export default withTheme(LoadingSpinner);
